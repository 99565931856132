import { auth, db } from "../../firebase";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../components/FirestoreTable";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import orangeMoneyLogo from "../../images/mobilemoney/orange.png";
import onatelMoneyLogo from "../../images/mobilemoney/onatel.png";
import corisMoneyLogo from "../../images/mobilemoney/corism.png";

export type transactiontype =
  | "BUYAIRTIME"
  | "BUYINTERNET"
  | "MOBILE_MONEY_TRANSFERT"
  | "MOBILE_MONEY_WITHDRAW"
  | "MOBILE_MONEY_ELECTRIC_METER";

const TRANSACTION_LABELS: Record<transactiontype, string> = {
  BUYAIRTIME: "Achat d'unité",
  BUYINTERNET: "Achat d'internet",
  MOBILE_MONEY_TRANSFERT: "Transfert d'argent",
  MOBILE_MONEY_WITHDRAW: "Retrait d'argent",
  MOBILE_MONEY_ELECTRIC_METER: "Recharge électricité",
};
const PAY_WITH_OPERATOR_IMAGES: Record<string, string> = {
  orange: orangeMoneyLogo,
  onatel: onatelMoneyLogo,
  corism: corisMoneyLogo,
};

function Transactions() {
  const columns: IColumn[] = [
    {
      field: "isConfirmedByUser",
      label: "Confirmé ?",
    },
    {
      field: "createdAt",
      label: "Date",
    },
    {
      field: "payWith",
      label: "Méthode de paiement",
    },
    {
      field: "beneficiaryNumber",
      label: "Bénéficiare",
    },
    {
      field: "transactionType",
      label: "Type de transaction",
    },
    {
      field: "forfait",
      label: "Votre achat",
    },
    {
      field: "amount",
      label: "Montant",
    },
    {
      field: "fees",
      label: "Frais",
    },
  ];
  const renderRow: RowRenderFunction = (
    row: IRow,
    column: IColumn,
    value: any
  ) => {
    switch (column.field) {
      case "createdAt":
        return <Moment locale="fr" format="L LT">{value.toDate() as Date}</Moment>;
      case "amount":
        return <span>{`${value} FCFA`}</span>;
      case "fees":
        return <span>{`${value} FCFA`}</span>;
      case "isConfirmedByUser":
        return value === true ? (
          <CheckCircleIcon className="w-6 h-6 mx-auto text-green-500" />
        ) : (
          <XCircleIcon className="w-6 h-6 mx-auto text-red-500" />
        );
      case "transactionType":
        return TRANSACTION_LABELS[`${value as transactiontype}`];
      case "payWith":
        return (
          <img
            className="inline-block h-10 rounded-md"
            src={PAY_WITH_OPERATOR_IMAGES[`${row["payWithOperatorCode"]}`]}
            alt={value}
          />
        );
      case "beneficiaryNumber":
        return (
          <>
            <p>{value}</p>
            {row["beneficiaryName"] && (
              <p className="text-sm text-gray-400">{row["beneficiaryName"]}</p>
            )}
          </>
        );
      default:
        return value as string;
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Transactions
          </h1>
          <p className="mt-2 text-base text-gray-700">
            Vous trouverez ici l'ensemble des transactions passées depuis YENGA
            sur votre compte.
          </p>
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Add user
        </button>
      </div> */}
      </div>
      <div className="flex flex-col mt-8 w-full">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle w-full">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <FirestoreTable
                collectionRef={db
                  .collection("users")
                  .doc(`${auth.currentUser?.uid}`)
                  .collection("transactions")}
                condensed
                columns={columns}
                rowRender={renderRow}
                paginate={{ pageIndex: 0, limit: 25, sort: "createdAt:desc" }}
              />
              {/* <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Confirmé ?
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Type de transaction
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Forfait
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Montant
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Frais
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Bénéficiare
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Méthode de paiement
                    </th>
                    {/* <th
                      scope="col"
                      className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th> 
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {transactions.map(
                    (transaction: any, transactionIdx: number) => (
                      <tr key={transaction.id}>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(transaction.isConfirmedByUser ?? true) ? (
                            <CheckCircleIcon className="w-6 h-6 text-green-500"/>
                          ): (
                            <XCircleIcon className="w-6 h-6 text-red-500"/>
                          )}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(
                            transaction.createdAt.toDate() as Date
                          ).toLocaleString()}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-900  font-medium hidden sm:table-cell"
                          )}
                        >
                          {`${
                            TRANSACTION_LABELS[
                              `${
                                transaction.transactionType as transactiontype
                              }`
                            ]
                          }`}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                          )}
                        >
                          {transaction.forfait}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                          )}
                        >
                          {`${transaction.amount} FCFA`}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          {`${transaction.fees} FCFA`}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                          )}
                        >
                          {`${transaction.beneficiaryNumber}${
                            transaction.beneficiaryName
                              ? ` ( ${transaction.beneficiaryName} )`
                              : ""
                          }`}
                        </td>
                        <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          )}
                        >
                          {`${transaction.payWith}`}
                        </td>
                        {/* <td
                          className={classNames(
                            transactionIdx !== transactions.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                          )}
                        >
                          <a
                            href="#"
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Edit
                            <span className="sr-only">
                              , {transaction.name}
                            </span>
                          </a>
                        </td> 
                      </tr>
                    )
                  )}
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
