import { useEffect, useState } from "react";
import { auth, db } from "../../firebase";

function Profile() {
  const [profile, setProfile] = useState<any>();
  useEffect(() => {
    db.collection("users")
      .doc(`${auth.currentUser?.uid}`)
      .get()
      .then((response) => {
        setProfile(response.data());
      });
  }, []);
  return (
    <main className="mx-auto max-w-lg px-4 pt-10 pb-12 lg:pb-16">
      {profile && (
        <form>
          <div className="space-y-6">
            <div>
              <h1 className="text-lg font-medium leading-6 text-gray-900">
                Mon profil
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Vous pouvez mettre à jour votre profil sur cette page
              </p>
            </div>

            <div>
              <label
                htmlFor="project-name"
                className="block text-sm font-medium text-gray-700"
              >
                Votre nom
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="displayName"
                  id="display-name"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                  defaultValue={profile.displayName}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="project-name"
                className="block text-sm font-medium text-gray-700"
              >
                Votre Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  disabled={true}
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 disabled:opacity-80 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                  defaultValue={profile.email}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="project-name"
                className="block text-sm font-medium text-gray-700"
              >
                Votre téléphone
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="phoneNumber"
                  id="phone-number"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                  defaultValue={profile.phoneNumber}
                />
              </div>
            </div>
            <div className="flex justify-end">
              {/* <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            >
              Cancel
            </button> */}
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:ring-offset-2"
              >
                Metre à jour mon profil
              </button>
            </div>
          </div>
        </form>
      )}
    </main>
  );
}

export default Profile;
