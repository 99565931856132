import { useState, useEffect, useCallback } from "react";

function useFetch(
  collectionRef: firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>,
  query: firebase.default.firestore.Query<firebase.default.firestore.DocumentData>,
  cursorDocumentSnapshot?: firebase.default.firestore.DocumentSnapshot
) {
  const [data, setData] = useState<any[]>();
  const [totalItems, setTotalItems] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [lastDocSnapshot, setlastDocSnapshot] =
    useState<firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>>();
  const [error, setError] = useState<any>();

  const refreshData = useCallback(() => {
    setLoading(true);
    const transformedQuery =
      cursorDocumentSnapshot != null
        ? query.startAfter(cursorDocumentSnapshot)
        : query;
    transformedQuery.onSnapshot(
      (querySnapshot) => {
        setLoading(false);
        const data = querySnapshot.docs.map((d) => d.data());
        setData(data);
        setlastDocSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
      },
      (err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      },
      () => {
        console.log("Query Completed");
        setLoading(false);
      }
    );
  }, [cursorDocumentSnapshot, query]);
  useEffect(() => {
    refreshData();
    setLoading(true);
    setData(undefined);
    setError(undefined);

    collectionRef.get().then((snapshot) => {
      setTotalItems(snapshot.size);
    });
  }, [refreshData, query, collectionRef]);

  return { data, lastDocSnapshot, totalItems, loading, error, refreshData };
}

export default useFetch;
