/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../utils";

export interface PaginationFooterProps {
  currentPageIndex: number;
  take: number;
  total: number;
  pageLimits?: number[];
  onPageChange?: (index: number) => void;
  onPageLimitChange?: (limit: number) => void;
}

export function PaginationFooter({
  currentPageIndex,
  take,
  total,
  pageLimits = [10, 25, 50, 100],
  onPageChange,
  onPageLimitChange,
}: PaginationFooterProps) {
  const numberOfPages = Math.floor(total / take) + 1;
  const maxIndex = take > total ? total : currentPageIndex * take + take;

  const goToPage = (pageIndex: number) => {
    if (onPageChange) onPageChange(pageIndex);
  };
  const changePageLimit = (pageLimit: number) => {
    if (onPageLimitChange) onPageLimitChange(pageLimit);
  };
  return (
    <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 dark:bg-gray-800 dark:border-gray-700 sm:px-6">
      <div className="flex justify-between flex-1 sm:hidden">
        <a
          onClick={() => {
            currentPageIndex === 0 ? void 0 : goToPage(currentPageIndex - 1);
          }}
          className={classNames(
            currentPageIndex === 0
              ? "disabled cursor-not-allowed bg-gray-50"
              : "cursor-pointer bg-white dark:bg-gray-800 hover:bg-gray-50",
            "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 "
          )}
        >
          {"Précédent"}
        </a>
        <a
          onClick={() => {
            currentPageIndex === numberOfPages - 1
              ? void 0
              : goToPage(currentPageIndex + 1);
          }}
          className={classNames(
            currentPageIndex === numberOfPages - 1
              ? "disabled cursor-not-allowed bg-gray-50"
              : "cursor-pointer bg-white dark:bg-gray-800 hover:bg-gray-50",
            "ml-3 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700"
          )}
        >
          {"Suivant"}
        </a>
      </div>
      <div className="hidden mr-8 md:flex-initial md:flex">
        <span className="relative z-0 inline-flex rounded-md shadow-sm">
          {pageLimits.map((pageLimit, index) => (
            <button
              key={index}
              type="button"
              onClick={() => changePageLimit(pageLimit)}
              className={classNames(
                index === 0 ? "rounded-l-md" : "",
                index === pageLimits.length - 1 ? "rounded-r-md" : "",
                pageLimit === take
                  ? "border-indigo-500 ring-indigo-500"
                  : "focus:border-indigo-500 focus:ring-indigo-500",
                "relative inline-flex items-center px-2 py-1  border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
              )}
            >
              {pageLimit}
            </button>
          ))}
        </span>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="">
          <p className="hidden text-sm text-gray-700 lg:inline-block">
            {"Résultat"}{" "}
            <span className="font-medium">{currentPageIndex * take + 1}</span>{" "}
            {"à"}{" "}
            <span className="font-medium">
              {maxIndex > total ? total : maxIndex}
            </span>{" "}
            {"sur un total de"} <span className="font-medium">{total}</span>{" "}
            {""}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              onClick={() => {
                currentPageIndex === 0
                  ? void 0
                  : goToPage(currentPageIndex - 1);
              }}
              className={classNames(
                currentPageIndex === 0
                  ? "disabled cursor-not-allowed bg-gray-50"
                  : "cursor-pointer bg-white dark:bg-gray-800 hover:bg-gray-50",
                "relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300  text-sm font-medium text-gray-500 "
              )}
            >
              <span className="sr-only">{"Précédent"}</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
              <span className="">{"Précédent"}</span>
            </a>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {/* {[...Array(numberOfPages)].map((e, i) => (
              <a
                key={i}
                aria-current="page"
                onClick={() => goToPage(i)}
                className={classNames(
                  currentPageIndex === i
                    ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                    : "bg-white dark:bg-gray-800 border-gray-300 text-gray-500 hover:bg-gray-50 cursor-pointer",
                  "relative inline-flex items-center px-2 py-1 border text-sm font-medium"
                )}
              >
                {i + 1}
              </a>
            ))} */}
            <a
              onClick={() => {
                currentPageIndex === numberOfPages - 1
                  ? void 0
                  : goToPage(currentPageIndex + 1);
              }}
              className={classNames(
                currentPageIndex === numberOfPages - 1
                  ? "disabled cursor-not-allowed bg-gray-50"
                  : "cursor-pointer bg-white hover:bg-gray-50",
                "relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300  text-sm font-medium text-gray-500 "
              )}
            >
              <span className="sr-only">{"Suivant"}</span>
              <span className="">{"Suivant"}</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default PaginationFooter;
