
import Root from "./routes/Root";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Dashboard from "./routes/Dashboard";
import Transactions from "./routes/Transactions";
import Profile from "./routes/Profile";
import Invoices from "./routes/Invoices";
import Moment from 'react-moment';
import 'moment/locale/fr';


function App() {
  Moment.globalLocale = 'fr';
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="transactions" element={<Transactions />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="profile" element={<Profile />} />
          <Route path="dasboard" element={<Dashboard />} />
          <Route path="" element={<Navigate to="transactions" />} />
        </Route>
        <Route path="*" element={<Navigate to="transactions" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
