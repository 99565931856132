// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGDIZiaZLcAc_7oX_QTCQ4N399UlrVAKE",
  authDomain: "transmobile-b14b6.firebaseapp.com",
  projectId: "transmobile-b14b6",
  storageBucket: "transmobile-b14b6.appspot.com",
  messagingSenderId: "48005487302",
  appId: "1:48005487302:web:d7b0b975368bcd4035d5c7",
  measurementId: "G-ZG7K35SN01",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics(app);
const auth = firebase.auth(app);
auth.languageCode = "fr";
auth.useDeviceLanguage();
// window.recaptchaVerifier = new RecaptchaVerifier(
//   document.getElementsByClassName("firebaseui-recaptcha-container"),
//   {
//     size: "invisible",
//     callback: (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       //onSignInSubmit();
//     },
//   },
//   app.auth()
// );
const db = firebase.firestore(app);

export { auth, db, analytics };
