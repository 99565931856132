/* eslint-disable @typescript-eslint/no-explicit-any */
import Alert from "../Alert";
import useFetch from "./useFetch";

export interface FetchProps {
  collectionRef: firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>;
  query: firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;
  lastDocumentPaginationCursor?: firebase.default.firestore.DocumentSnapshot;
  renderData: (
    data: any,
    lastDocSnapshot:
      | firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
      | undefined
  ) => React.ReactNode;
  renderLoading?: (
    loading: boolean,
    loadingSkeletonCount: number
  ) => React.ReactNode;
  renderError?: (error: any) => React.ReactNode;
  loadingSkeletonUseAvatar?: boolean;
  loadingSkeletonCount?: number;
  refreshHandler?: (refreshData: () => void) => void;
  fetchDataMapper?: (data: any, totalItems?: number) => any;
}

export function FirestoreFetch({
  fetchDataMapper = (data: any, totalItems?: number) => data,
  ...props
}: FetchProps) {
  const { data, lastDocSnapshot, totalItems, loading, error, refreshData } =
    useFetch(
      props.collectionRef,
      props.query,
      props.lastDocumentPaginationCursor
    );
  if (props.refreshHandler) {
    props.refreshHandler(refreshData);
  }
  return (
    <>
      {loading &&
        (!props.renderLoading ? (
          <div className="max-w-5xl p-4 mx-auto">
            {[...Array(props.loadingSkeletonCount || 5)].map((e, i) => (
              <div
                key={`animate-pulse-${i}`}
                className="flex my-1 space-x-4 animate-pulse"
              >
                {props.loadingSkeletonUseAvatar && (
                  <div className="w-12 h-12 bg-gray-400 rounded-full"></div>
                )}
                <div className="flex-1 py-1 space-y-6">
                  <div className="w-3/4 h-4 bg-gray-400 rounded"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-400 rounded"></div>
                    <div className="w-5/6 h-4 bg-gray-400 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          props.renderLoading(loading, props.loadingSkeletonCount || 5)
        ))}
      {data &&
        props.renderData(fetchDataMapper(data, totalItems), lastDocSnapshot)}
      {error &&
        (props.renderError ? (
          props.renderError(error)
        ) : (
          <Alert
            type="danger"
            title="Error fetching datas."
            content={
              (error as any).message ||
              "Please retry or contact an administrator."
            }
          />
        ))}
    </>
  );
}

export default FirestoreFetch;
